<template>
  <div>
    <!-- Header Alert -->
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">Daftar <b>Jadwal Kuliah</b></div>
      <!-- <b-button
        squared
        @click="$router.push('/schedules/add')"
        v-if="btnAccess"
        variant="primary"
        >Tambah</b-button
      > -->
    </b-alert>

    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div>
              <!-- Filter -->
              <div class="row justify-content-start mt-n3">
                <div class="col-md-3">
                  <treeselect
                    v-model="filter.semester_id"
                    :multiple="false"
                    placeholder="Saring Semester"
                    :options="semesters"
                    @input="filterBySemester"
                  />
                </div>
                <div class="col-md-3">
                  <treeselect
                    v-model="filter.study_program_id"
                    :multiple="false"
                    placeholder="Saring Program Studi"
                    :options="study_programs"
                    @input="filterByStudyProgram"
                  />
                </div>
                <div class="col-3 offset-md-3 text-right">
                  <b-button
                    variant="success"
                    class="ml-1 mt-1"
                    @click="btnPrintOnClick"
                    v-b-tooltip.hover
                    title="Print"
                    size="sm"
                  >
                    <i class="fas fa-print px-0"></i>
                  </b-button>
                  <vue-excel-xlsx
                    :data="items"
                    :columns="columns"
                    :filename="`Jadwal Kuliah`"
                    :sheetname="`Jadwal Kuliah`"
                  >
                    <b-button
                      class="ml-1 mt-1 btn-green"
                      variant="btn-green"
                      v-b-tooltip.hover
                      title="Cetak Excel"
                      size="sm"
                    >
                      <i class="fas fa-file-excel text-white px-0"></i>
                    </b-button>
                  </vue-excel-xlsx>
                  <b-button
                    variant="danger"
                    class="ml-1 mt-1"
                    @click="downloadPDF"
                    v-b-tooltip.hover
                    title="Cetak PDF"
                    size="sm"
                  >
                    <i class="fas fa-file-pdf text-white px-0"></i>
                  </b-button>
                </div>
              </div>
              <b-table
                striped
                hover
                :items="items"
                :fields="fields"
                class="mt-3"
                responsive
              >
                <template #table-colgroup="scope">
                  <col
                    v-for="field in scope.fields"
                    :key="field.key"
                    :style="{ width: field.key === 'subject_name' ? '6%' : '' }"
                  />
                </template>

                <template #cell(time)="data">
                  <template v-for="(item, i) in data.item.schedule_attributes">
                    <div
                      class="font-weight-bold"
                      :class="i != 0 ? 'mt-2' : ''"
                      :key="'time' + item.id"
                    >
                      {{ item.day_name }}
                    </div>
                    <span
                      class="d-block"
                      v-for="list in item.times"
                      :key="list.id"
                    >
                      {{ list.start_time + "-" + list.end_time }}
                    </span>
                  </template>
                </template>

                <template #cell(building)="data">
                  <span
                    class="d-block"
                    v-for="item in data.item.schedule_attributes"
                    :key="'building' + item.id"
                  >
                    {{ item.building_name + "-" + item.room_name }}
                  </span>
                </template>

                <template #cell(teacher)="data">
                  <template v-for="(item, i) in data.item.schedule_attributes">
                    <div
                      class="font-weight-bold"
                      :class="i != 0 ? 'mt-2' : ''"
                      :key="'time' + item.id"
                    ></div>
                    <span
                      class="d-block"
                      v-for="list in item.teachers"
                      :key="list.id"
                    >
                      {{ list.name }}
                    </span>
                  </template>
                </template>

                <template #cell(class_type)="data">
                  <span
                    class="d-block"
                    v-for="item in data.item.schedule_attributes"
                    :key="'class' + item.id"
                  >
                    {{ item.class_type_name }}
                  </span>
                </template>

                <template #cell(subject_name)="data">
                  <span class="d-block font-weight-bold">
                    {{ data.item.subject_code }}
                  </span>
                  <span class="d-block">
                    {{ data.item.subject_name }}
                  </span>
                </template>

                <template #cell(action)="data">
                  <b-button
                    size="sm"
                    class="mr-1 btn-circle btn-outline-info"
                    v-b-tooltip.hover
                    title="Detail"
                    @click="
                      $router.push({
                        path: '/schedules/detail/' + data.item.id,
                      })
                    "
                    ><i class="fas fa-eye px-0"></i
                  ></b-button>
                  <b-button
                    size="sm"
                    class="mr-1 btn-circle btn-outline-success"
                    v-b-tooltip.hover
                    title="Edit"
                    @click="
                      $router.push({
                        path: '/schedules/edit/' + data.item.id,
                      })
                    "
                    v-if="btnAccess"
                    ><i class="fas fa-edit px-0"></i
                  ></b-button>
                  <b-button
                    size="sm"
                    class="btn-circle btn-outline-danger"
                    v-b-tooltip.hover
                    title="Hapus"
                    @click="deleteData(data.item.id)"
                    v-if="
                      data.item.lab_material_stocks == null && btnAccess == true
                    "
                    ><i class="fas fa-trash px-0"></i
                  ></b-button>
                </template>
              </b-table>

              <b-pagination
                v-if="items.length != 0"
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                @page-click="pageOnClick"
                class="mt-4"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>

    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="500"
      filename="Jadwal Kuliah"
      :pdf-quality="1"
      :manual-pagination="true"
      pdf-format="a4"
      pdf-orientation="landscape"
      pdf-content-width="1100px"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <TablePrintPdf :itemProps="items" />
      </section>
    </vue-html2pdf>
    <TablePrint :itemProps="items" class="d-none" />
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import module from "@/core/modules/CrudModule.js";
import VueHtml2pdf from "vue-html2pdf";
import TablePrintPdf from "@/view/pages/schedules/TablePrintPdf.vue";
import TablePrint from "@/view/pages/schedules/TablePrint.vue";

export default {
  components: {
    VueHtml2pdf,
    TablePrintPdf,
    TablePrint,
  },
  data() {
    return {
      // filter
      filter: {
        name: "",
        semester_id: "",
        study_program_id: "",
      },
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      fields: [
        {
          key: "number",
          label: "No",
          sortable: true,
        },
        {
          key: "semester_group_name",
          label: "Semester",
          sortable: true,
        },
        {
          key: "time",
          label: "Waktu",
          sortable: true,
          tdClass: "nowrap",
        },
        {
          key: "building",
          label: "Gedung/Ruang",
          sortable: true,
        },
        {
          key: "capacity",
          label: "Kapasitas",
          sortable: true,
        },
        {
          key: "subject_name",
          label: "Mata Kuliah",
          sortable: true,
        },
        {
          key: "subject_sks",
          label: "SKS",
          sortable: true,
        },
        {
          key: "teacher",
          label: "Dosen",
          sortable: true,
        },
        {
          key: "class_type",
          label: "Kelas",
          sortable: true,
        },
        // {
        //   key: "action",
        //   label: "Aksi",
        //   tdClass: "nowrap",
        // },
      ],
      columns: [
        {
          field: "semester_name",
          label: "Semester",
        },
        {
          field: "time_excel",
          label: "Waktu",
        },
        {
          field: "building_excel",
          label: "Gedung/Ruang",
        },
        {
          field: "capacity",
          label: "Kapasitas",
        },
        {
          field: "subject_name",
          label: "Mata Kuliah",
        },
        {
          field: "subject_sks",
          label: "SKS",
        },
        {
          field: "teacher_excel",
          label: "Dosen",
        },
        {
          field: "class_type_excel",
          label: "Kelas",
        },
      ],
      items: [],
      client_types: [],
      tool_statuses: [],
      semesters: [],
      study_programs: [],
      // access
      btnAccess: false,
    };
  },
  methods: {
    pageOnClick(evt, page) {
      evt.preventDefault();
      //("evt", evt);
      //("page", page);
      this.currentPage = page;
      this.pagination();
    },

    async pagination() {
      let filterParams = `&name=${this.filter.name}&semester_id=${this.filter.semester_id}&study_program_id=${this.filter.study_program_id}`;
      let response = await module.paginate(
        "api/schedules",
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }

      this.items = response.data;
    },

     async getSemesterActive() {
      let response = await module.get("api/semesters/now");
      // If Data Not Found
      if ( response != null) {
        // console.log('response', response)
        this.filter.semester_id = response.id
        this.pagination()
      }else{
        this.pagination()
      }
    },

    async getSemesterOption() {
      let response = await module.setTreeSelect("api/semesters");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.semesters = response.data;
        this.semesters.unshift({
          label: "Saring Semester",
          id: "",
          isDisabled: true,
        });
      }
    },

    async getStudyProgramOption() {
      let response = await module.setTreeSelect("api/study-programs");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.study_programs = response.data;
        this.study_programs.unshift({
          label: "Saring Program Studi",
          id: "",
          isDisabled: true,
        });
      }
    },

    filterBySemester(evt) {
      if (typeof evt == "undefined") {
        this.filter.semester_id = "";
      }
      this.pagination();
    },

    filterByStudyProgram(evt) {
      if (typeof evt == "undefined") {
        this.filter.study_program_id = "";
      }
      this.pagination();
    },

    search() {
      this.pagination();
    },
    reset() {
      this.filter.name = "";
      this.filter.semester_id = "";
      this.pagination();
    },
    async btnPrintOnClick() {
      const options = {
        name: "_blank",
        specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
        styles: [
          "https://maxcdn.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css",
          "https://unpkg.com/kidlat-css/css/kidlat.css",
          `${process.env.BASE_URL}assets/css/print-landscape.css`
        ],
        timeout: 1000,
        autoClose: true,
        windowTitle: "Jadwal Kuliah",
      };
      await this.$htmlToPaper("printMe", options);
    },
    downloadPDF() {
      this.$refs.html2Pdf.generatePdf();
    },
    async deleteData(id) {
      // Delete Data
      let result = await module.delete("api/schedule-attributes/" + id);
      // If Deleted
      if (result) {
        this.pagination();
      }
    },

    // access
    checkAccess() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "1101") {
          this.btnAccess = true;
        }
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Jadwal Kuliah", route: "/schedules" },
    ]);
    // this.pagination();
    this.getSemesterActive();
    this.getSemesterOption();
    this.getStudyProgramOption();
    this.checkAccess();
  },
};
</script>

<style>
.nowrap {
  white-space: nowrap;
}

.image-input-wrapper {
  width: 50px !important;
  height: 50px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.sr-only {
  width: 0px !important;
}
</style>

<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div>
              <b-table :items="itemProps" :fields="fields" class="mt-3">
                <template #table-colgroup="scope">
                  <col
                    v-for="field in scope.fields"
                    :key="field.key"
                    :style="{ width: field.key === 'subject_name' ? '6%' : '' }"
                  />
                </template>

                <template #cell(time)="data">
                  <template v-for="(item, i) in data.item.schedule_attributes">
                    <div
                      class="font-weight-bold"
                      :class="i != 0 ? 'mt-2' : ''"
                      :key="'time' + item.id"
                    >
                      {{ item.day_name }}
                    </div>
                    <span
                      class="d-block"
                      v-for="list in item.times"
                      :key="list.id"
                    >
                      {{ list.start_time + "-" + list.end_time }}
                    </span>
                  </template>
                </template>

                <template #cell(building)="data">
                  <span
                    class="d-block"
                    v-for="item in data.item.schedule_attributes"
                    :key="'building' + item.id"
                  >
                    {{ item.building_name + "-" + item.room_name }}
                  </span>
                </template>

                <template #cell(teacher)="data">
                  <template v-for="(item, i) in data.item.schedule_attributes">
                    <div
                      class="font-weight-bold"
                      :class="i != 0 ? 'mt-2' : ''"
                      :key="'time' + item.id"
                    ></div>
                    <span
                      class="d-block"
                      v-for="list in item.teachers"
                      :key="list.id"
                    >
                      {{ list.name }}
                    </span>
                  </template>
                </template>

                <template #cell(class_type)="data">
                  <span
                    class="d-block"
                    v-for="item in data.item.schedule_attributes"
                    :key="'class' + item.id"
                  >
                    {{ item.class_type_name }}
                  </span>
                </template>

                <template #cell(subject_name)="data">
                  <span class="d-block font-weight-bold">
                    {{ data.item.subject_code }}
                  </span>
                  <span class="d-block">
                    {{ data.item.subject_name }}
                  </span>
                </template>

                <template #cell(action)="data">
                  <b-button
                    size="sm"
                    class="mr-1 btn-circle btn-outline-info"
                    v-b-tooltip.hover
                    title="Detail"
                    @click="
                      $router.push({
                        path: '/schedules/detail/' + data.item.id,
                      })
                    "
                    ><i class="fas fa-eye px-0"></i
                  ></b-button>
                  <b-button
                    size="sm"
                    class="mr-1 btn-circle btn-outline-success"
                    v-b-tooltip.hover
                    title="Edit"
                    @click="
                      $router.push({
                        path: '/schedules/edit/' + data.item.id,
                      })
                    "
                    v-if="btnAccess"
                    ><i class="fas fa-edit px-0"></i
                  ></b-button>
                  <b-button
                    size="sm"
                    class="btn-circle btn-outline-danger"
                    v-b-tooltip.hover
                    title="Hapus"
                    @click="deleteData(data.item.id)"
                    v-if="
                      data.item.lab_material_stocks == null && btnAccess == true
                    "
                    ><i class="fas fa-trash px-0"></i
                  ></b-button>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import module from "@/core/modules/CrudModule.js";

export default {
  props: {
    itemProps: Array,
  },
  data() {
    return {
      // filter
      filter: {
        name: "",
        semester_id: "",
        study_program_id: "",
      },
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      fields: [
        {
          key: "number",
          label: "No",
        },
        {
          key: "semester_group_name",
          label: "Semester",
        },
        {
          key: "time",
          label: "Waktu",
          tdClass: "nowrap",
        },
        {
          key: "building",
          label: "Gedung/Ruang",
        },
        {
          key: "capacity",
          label: "Kapasitas",
        },
        {
          key: "subject_name",
          label: "Mata Kuliah",
        },
        {
          key: "subject_sks",
          label: "SKS",
        },
        {
          key: "teacher",
          label: "Dosen",
        },
        {
          key: "class_type",
          label: "Kelas",
        },
      ],
      columns: [
        {
          field: "semester_name",
          label: "Semester",
        },
        {
          field: "time_excel",
          label: "Waktu",
        },
        {
          field: "building_excel",
          label: "Gedung/Ruang",
        },
        {
          field: "capacity",
          label: "Kapasitas",
        },
        {
          field: "subject_name",
          label: "Mata Kuliah",
        },
        {
          field: "subject_sks",
          label: "SKS",
        },
        {
          field: "teacher_excel",
          label: "Dosen",
        },
        {
          field: "class_type_excel",
          label: "Kelas",
        },
      ],
      items: [],
      client_types: [],
      tool_statuses: [],
      semesters: [],
      study_programs: [],
      // access
      btnAccess: false,
    };
  },
  methods: {
    pageOnClick(evt, page) {
      evt.preventDefault();
      //("evt", evt);
      //("page", page);
      this.currentPage = page;
      this.pagination();
    },

    async pagination() {
      let filterParams = `&name=${this.filter.name}&semester_id=${this.filter.semester_id}&study_program_id=${this.filter.study_program_id}`;
      let response = await module.paginate(
        "api/schedules",
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }

      this.items = response.data;
    },

    async getSemesterOption() {
      let response = await module.setTreeSelect("api/semesters");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.semesters = response.data;
        this.semesters.unshift({
          label: "Saring Semester",
          id: "",
          isDisabled: true,
        });
      }
    },

    async getStudyProgramOption() {
      let response = await module.setTreeSelect("api/study-programs");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.study_programs = response.data;
        this.study_programs.unshift({
          label: "Saring Program Studi",
          id: "",
          isDisabled: true,
        });
      }
    },

    filterBySemester(evt) {
      if (typeof evt == "undefined") {
        this.filter.semester_id = "";
      }
      this.pagination();
    },

    filterByStudyProgram(evt) {
      if (typeof evt == "undefined") {
        this.filter.study_program_id = "";
      }
      this.pagination();
    },

    search() {
      this.pagination();
    },
    reset() {
      this.filter.name = "";
      this.filter.semester_id = "";
      this.pagination();
    },

    async deleteData(id) {
      // Delete Data
      let result = await module.delete("api/schedule-attributes/" + id);
      // If Deleted
      if (result) {
        this.pagination();
      }
    },

    // access
    checkAccess() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "1101") {
          this.btnAccess = true;
        }
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Jadwal Kuliah", route: "/schedules" },
    ]);
    this.pagination();
    this.getSemesterOption();
    this.getStudyProgramOption();
    this.checkAccess();
  },
};
</script>

<style>
.nowrap {
  white-space: nowrap;
}

.image-input-wrapper {
  width: 50px !important;
  height: 50px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.sr-only {
  width: 0px !important;
}
</style>
